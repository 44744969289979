import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';

import { getMainDefinition } from '@apollo/client/utilities';
import result from './generated/graphql';
import { onError } from '@apollo/client/link/error';
import { t } from 'i18next';
import { isProductionBranch } from 'utils/env';
import { takeRight, uniq } from 'lodash';
import { OperationDefinitionNode } from 'graphql';

const wsLink = (wsUrl: string, getToken: () => Promise<string>) =>
  new GraphQLWsLink(
    createClient({
      url: wsUrl,
      lazy: true,
      connectionParams: async () => {
        // The function is called to get the token each time a connection is made
        const token = await getToken();
        return { authorization: `Bearer ${token}` };
      },
    })
  );

enum ErrorCodes {
  API_ACCESS_DENIED = 'access denied',
}

type ApiWarning = {
  entityType: string;
  id: string;
  message: string;
  path: string[];
  type: ApiWarningType;
};

enum ApiWarningType {
  AccessDenied = 'AccessDeniedWarning', // Indicates that the user has made a query where some sub-parts of the query is something the  user do not have access to. Could be a problem if it is not intended.
  MyPermissionsWarning = 'MyPermissionsWarning', // Indicates that we are requesting myPermissions on an entity in a non-authenticated context (for instance a sub-entity of something else). This will trigger a full access check on the entity in separate SQL queries, making it a potential performance issue.
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const apiAccessDeniedError = graphQLErrors.find(
      (err) => err.message === ErrorCodes.API_ACCESS_DENIED
    );
    if (apiAccessDeniedError) {
      // The entire query failed because of invalid (expired) auth token
      if (confirm(t('SESSION_EXPIRED'))) {
        window.location.reload();
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

// Link that logs acces denied warnings from the API
const warningLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const warnings = response.extensions?.warnings as ApiWarning[];
    const accessDeniedWarnings = warnings?.filter(
      (warning) => warning.type === ApiWarningType.AccessDenied
    );
    const myPermissionsWarnings = warnings?.filter(
      (warning) => warning.type === ApiWarningType.MyPermissionsWarning
    );
    if (accessDeniedWarnings?.length) {
      // Parts of the query failed because of access denied. Log to Sentry
      const entities = uniq(accessDeniedWarnings.map((warning) => warning.entityType));
      const errorText = `Unauthorized access in query ${operation.operationName}: ${entities.join(
        ', '
      )}`;
      console.error(errorText);
      Sentry.withScope((scope) => {
        scope.setExtras({
          query: operation.query.loc?.source.body,
        });
        Sentry.captureException(new Error(errorText));
      });
    }
    const isQuery = operation.query.definitions
      .filter((def) => def.kind === 'OperationDefinition')
      .every((def) => (def as OperationDefinitionNode).operation === 'query');

    if (myPermissionsWarnings?.length && isQuery) {
      // For mutations, we don't want to log this warning, as it's expected. For queries, it's a potential performance issue
      console.warn('MyPermissionsWarning in query', operation.operationName, myPermissionsWarnings);
    }
    return response;
  });
});

export const ApolloClientFactory = (
  backendUrl: string | undefined,
  getAccessTokenSilently: () => Promise<string>
) => {
  if (backendUrl === undefined) throw Error('No API URL defined');

  // Create an Apollo link that checks and refreshes the token if needed
  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  // Strip away the protocol from the backend URL
  const webSocketUrl = backendUrl?.replace('http://', 'ws://')?.replace('https://', 'wss://');

  return new ApolloClient({
    uri: backendUrl,
    link: ApolloLink.from([
      authLink,
      errorLink,
      graphiqlHistoryLogger,
      warningLink,
      // Split the request into the websocket or http depending on whether it's a subscription
      ApolloLink.split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
          );
        },
        wsLink(webSocketUrl, getAccessTokenSilently),
        new HttpLink({
          uri: backendUrl,
        })
      ),
    ]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
    cache: new InMemoryCache({
      possibleTypes: result.possibleTypes,
      typePolicies: {
        BatteryMeasurement: {
          keyFields: false, // Disable normalization for measurements. They have no ID
        },
        EnergyMeterMeasurement: {
          keyFields: false, // Disable normalization for measurements. They have no ID
        },
        BatteryHubAspect: {
          keyFields: false,
          merge: true,
        },
        Subscription: {
          fields: {
            deviceMeasurements: {
              merge(existing, incoming, { cache }) {
                const assetId = incoming.assetId;
                // Find the corresponding Asset object in the cache
                const assetRef = cache.identify({ __typename: 'Asset', id: assetId });
                if (assetRef) {
                  // Update the latestMeasurement field of the Asset object
                  cache.modify({
                    id: assetRef,
                    fields: {
                      latestMeasurement() {
                        return incoming;
                      },
                    },
                  });
                }
                // Return the incoming measurement as the new state
                return incoming;
              },
            },
          },
        },
      },
    }),
    connectToDevTools: !isProductionBranch,
  });
};

// This middleware logs all queries to local storage
// This means that all the queries you run in the GraphiQL interface will be stored, and accessible in the GraphQL Playground
const graphiqlHistoryLogger = new ApolloLink((operation, forward) => {
  const maxStoredQueries = 30;

  return forward(operation).map((response) => {
    const query = operation.query.loc && operation.query.loc.source.body;
    const variables = JSON.stringify(operation.variables);

    // Do not log responses from subscriptions, as they will flood the logs, and are not really useful
    if (
      operation.query.definitions.some(
        (def) => def.kind === 'OperationDefinition' && def.operation === 'subscription'
      )
    ) {
      return response;
    }

    if (query) {
      const existingQueries = localStorage.getItem('graphiql:queries');
      const stored = existingQueries ? JSON.parse(existingQueries) : { queries: [] };

      // Prettify the query by replacing unwanted spaces
      const prettyfiedQuery = query
        .replace(/\n {4}query/g, '\n query')
        .replace(/\n {4}fragment/g, '\n fragment');

      const prettyfiedVariables = JSON.stringify(JSON.parse(variables), null, 4);

      // Take the last maxStoredQueries queries
      const lastStored = takeRight(stored.queries, maxStoredQueries);

      const newQuery = {
        query: prettyfiedQuery,
        variables: prettyfiedVariables,
        operationName: operation.operationName,
      };
      localStorage.setItem(
        'graphiql:queries',
        JSON.stringify({ ...stored, queries: [...lastStored, newQuery] })
      );
    }

    return response;
  });
});
